import React from "react"
import NoSidebarLayout from "../layout/NoSidebarLayout"
import { graphql } from "gatsby"
import ReactHtmlParser from "react-html-parser";
import Fade from 'react-reveal/Fade'
import { news, blogBanner } from "../images";
import styled from 'styled-components'
import SEO from "../components/seo";
import { RiContrastDropLine } from "react-icons/ri";

const StyledImg = styled.img `
  max-width: calc(100px + 15vw);
  height: auto;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 10px;
`

const ImageContainer = styled.div `
  max-width: 300px;
`

const Header = styled.div `
  display: flex;
  justify-content: center;
  max-width: 100%;

  @media only screen and (max-width: 1000px){
    flex-wrap: wrap;
  }
`

const HeaderText = styled.div `
  display: flex;
  flex-direction: column;
  margin: 10px;
  max-width: 70%;
  @media (min-width: 2000px){
  max-width: 50%; 
  }
`

const HeaderTitle = styled.h1 `
  font-size: calc(20px + 1.5vw);
`

export default ({ data }) => {
  const post = data.allWpPost.edges[0].node
  const content = post.content
  const processedData = ReactHtmlParser(content)
  return (
      <NoSidebarLayout pageTitle='Blog' background={blogBanner}>
        <SEO title={post.seo.opengraphTitle} description={post.seo.opengraphDescription}/>
        <div className='blog-post'>
          <Header>
            <HeaderText>
          <HeaderTitle>{post.title}</HeaderTitle>
          <br/>
          <h2>{post.date}</h2>
          {processedData.map((data, index) => {
            return (
              <Fade>
                <div className='blog-post'>{data}</div>
              </Fade>
            )
          })}
            </HeaderText>
            <br />
          {(post.featuredImage) && <ImageContainer><StyledImg src={post.featuredImage.node.mediaItemUrl}/></ImageContainer> }
          </Header>
        </div>
      </NoSidebarLayout>
  )
}

export const query = graphql `
  query($slug: String!) {
    allWpPost(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          content
          date(formatString: "MM-DD-YYYY")
          featuredImage {
            node {
              mediaItemUrl
            }
          }
          seo {
            opengraphDescription
            opengraphTitle
          }
        }
      }
    }
  }
`